<template>
  <div class="about">
    <h2>关于我们</h2>
    <div class="about-content">
      <p>
        &emsp;&emsp;广东南大教育咨询服务有限公司，是广东省具有强大实力的成人继续教育培训机构。可全面满足广大学子成人继续教育的需求，为广大有意愿提升学历的学子提供专业的学历提升咨询服务。南大教育通过对学生的全程个性化服务流程，为每一个学生进行个性化的诊断测评，匹配合适的专业，量身定做个性化的学历提升途径，从咨询到报名、报考、入学、毕业进行一条龙服务，全程有专人跟踪服务，直到把毕业证送到广大学子手中。
      </p>
      <p>
        &emsp;&emsp;我司与广州理工学院、广东南方职业学院建立了长期合作关系，为社会培养技术、管理人才，为教育事业发展做出重大贡献。强化服务理念、优化教育资源、改革教学模式、提升办学品质，以一流的教育教学质量作为报国之资和立身之本。
      </p>
      <p>
        &emsp;&emsp;办学至今，南大教育汇聚了一支教学经验丰富、熟悉教育特点的名师队伍，同时，打造了一支高素质、具有丰富教育管理经验的管理服务团队，先进的教育辅导系统、独特高效的教育方法和完善的管理模式，已向全省广大学子提供了学历提升教育服务，并先后帮助广大学子实现了学历提升，得到广大学子的一致好评。目前，南大教育已成为广大学子学历提升的首选，成为深受信赖的教育品牌！
      </p>
      <p>
        &emsp;&emsp;展望未来，南大教育不断顺应社会经济发展的需求，积极探究继续教育发展规律，深化继续教育内涵，务实创新，为社会发展、经济建设不断培养大批实用人才！
      </p>
      <!-- <div class="image">
        <img
          src="https://www.gdnandaedu.com/uploads/202106/1623125047371106.jpg"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.about {
  padding: 70px 0px;
  .about-content {
    line-height: 30px;
    font-size: 16px;
    p {
      margin: 15px;
    }
  }
  .image{
    width: 805px;
    margin: 0px auto;
  }
}
</style>
